import { TFunction } from 'i18next';
import { AlertCircle, Basket, Beach, BuildingCommunity, CalendarEvent, Icon, Notebook } from 'tabler-icons-react';

type ForecastSpecialEffort = {
  id: number;
  key: string;
  descriptionKey: string;
};

export type SpecialEffortItem = Omit<ForecastSpecialEffort, 'key'> & {
  name: string;
};

export const specialEffortIconsMap: Map<number, Icon> = new Map([
  [1, Beach],
  [2, BuildingCommunity],
  [3, Basket],
  [4, CalendarEvent],
  [5, AlertCircle],
  [6, Notebook]
]);

const FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT: readonly ForecastSpecialEffort[] = Object.freeze([
  {
    id: 3,
    key: 'forecast.special-effort.sales-support',
    descriptionKey: 'forecast.legend.info.sales'
  },
  {
    id: 5,
    key: 'forecast.special-effort.idle',
    descriptionKey: 'forecast.legend.info.idle'
  },
  {
    id: 6,
    key: 'forecast.special-effort.other',
    descriptionKey: 'forecast.legend.info.other'
  }
]);

const ALL_SPECIAL_EFFORTS: readonly ForecastSpecialEffort[] = Object.freeze([
  {
    id: 1,
    key: 'forecast.special-effort.vacation',
    descriptionKey: 'forecast.legend.info.vacation'
  },
  {
    id: 2,
    key: 'forecast.special-effort.sick-or-leave',
    descriptionKey: 'forecast.legend.info.sick'
  },
  {
    id: 4,
    key: 'forecast.special-effort.bank-holiday',
    descriptionKey: 'forecast.legend.info.bank'
  },
  ...FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT
]);

export function getManualForecastSpecialEffortItems(t?: TFunction): SpecialEffortItem[] {
  const list: SpecialEffortItem[] = FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT.map((effort) => ({
    ...effort,
    key: undefined,
    name: t(effort.key)
  }));

  return list.sort((a, b) => a.id - b.id);
}

export function getSpecialEffortIcon(specialEffort: number): Icon | null {
  if (!specialEffortIconsMap.has(specialEffort)) {
    return null;
  }

  return specialEffortIconsMap.get(specialEffort);
}

export const canSpecialEffortBeManuallyUpdated = (specialEffort: number) =>
  FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT.some((item) => item.id === specialEffort);

export const getSpecialEffortNameKey = (specialEffort: number) =>
  ALL_SPECIAL_EFFORTS.find(({ id }) => id === specialEffort)?.key;
